import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { Section, Container } from "../grid/grid"
import { Button } from "../buttons/buttons"

export const AcfColHeadlineBlock = graphql`
  fragment AcfColHeadlineBlock on WordPress_AcfColHeadlineBlock {
    name
    acf {
      text
      title
      bg_color
    }
  }
`

export const AcfColHeadline = ({ data }) => {
  const colHeadline = data.acf
  return (
    <Section
      className={`${
        colHeadline.bg_color
          ? `bg-${colHeadline.bg_color
              .split("bg")
              .join("")
              .toLowerCase()}`
          : ""
      }`}
    >
      <Container>
        <div className="row">
          <div className="col-lg-5 col-xl-6">
            <h2 className="h2">{colHeadline.title}</h2>
          </div>
          <div className="col-lg-7 col-xl-6">
            <ReactMarkdown source={colHeadline.text} escapeHtml={false} />
            {colHeadline.list &&
              colHeadline.list.length > 0 &&
              !undefined &&
              colHeadline.list.map((item, i) => {
                return (
                  <div className="icon-text">
                    <img src={item.icon.sourceUrl} />
                    <p>{item.text}</p>
                  </div>
                )
              })}
            {colHeadline.buttons &&
              colHeadline.buttons.map((button, i) => {
                return (
                  <Button
                    variant="secondary"
                    className="btn-block-down-md mt-4"
                    link={button.link && button.link.url}
                    key={`${i}`}
                  >
                    {button.text} <i className="fas fa-arrow-right ml-1"></i>
                  </Button>
                )
              })}
          </div>
        </div>
      </Container>
    </Section>
  )
}
